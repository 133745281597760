import React, { useEffect, useState } from 'react';
import ModalEditar from '../components/ModalEditarCliente';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faUser, faClockRotateLeft, faChartSimple, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import ModalGraficoVendas from './../components/ModalGraficoVendas';
import CardAutorizacaoClientes from '../components/CardAutorizacaoClientes';

const AutorizacaoClientes = ({ user, loading }) => {
    const [clientes, setClientes] = useState([]);
    const [clientesFiltrado, setClientesFiltrado] = useState([]);

    const [vendedores, setVendedores] = useState([]);
    const [clienteSelecionado, setclienteSelecionado] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [filtro, setFiltro] = useState({ status: '0' });

    const handleShowModal = async (clientesel) => {
        setclienteSelecionado(clientesel);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setclienteSelecionado(null);
        setShowModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFiltro({ ...filtro, [name]: value.toUpperCase() });
    };

    useEffect(() => {
        if (showModal) return;
        const fetchClientes = async () => {
            try {
                if (user?.idCaracteristica == null) return;

                const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/GetAutorizacaoCliente');

                if (!response.ok) {
                    throw new Error('Erro ao carregar clientes');
                }

                const data = await response.json();

                setClientes(data);
                setClientesFiltrado(data);
            } catch (error) {
                console.error('Erro ao carregar clientes:', error);
            }
        };

        const fetchVendedores = async () => {
            try {
                const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/GetFranquiados');

                if (!response.ok) {
                    throw new Error('Erro ao carregar clientes');
                }
                const data = await response.json();
                setVendedores(data);
            } catch (e) {
                console.error('Erro ao carregar vendedores:', e);
            }
        };

        fetchVendedores();
        fetchClientes();
    }, [user, showModal]); // Dependência adicionada

    useEffect(() => {
        const fetchFiltrar = async () => {
            var cli = clientes?.filter((cli, idx) => {
                const dataConsideradaParaFiltro = filtro.status == 1 ? cli.DataAlteracao : cli.DataCadastro;

                const data = new Date(dataConsideradaParaFiltro);

                const ano = data.getFullYear();
                const mes = (data.getMonth() + 1).toString().padStart(2, '0');
                const dia = data.getDate().toString().padStart(2, '0');

                const dataFormatada = `${ano}-${mes}-${dia}`;

                return (!filtro.dataInicio || dataFormatada >= filtro.dataInicio) &&
                    (!filtro.dataFim || dataFormatada <= filtro.dataFim) &&
                    (!filtro.status || cli.Status == filtro.status) &&
                    (!filtro.vendedor || cli.idUsuarioFranquia == filtro.vendedor)

            });
            setClientesFiltrado(cli);
        };
        fetchFiltrar();
    }, [filtro, clientes]);

    return (
        <div>
            <div className="card m-2 shadow-lg rounded bg-dark">
                <div className="card-header p-3">
                    <div className="row text-white">
                        <div className="col-6 col-sm-3 col-lg-2">
                            <label htmlFor="inputDataInicio" className="form-label"><FontAwesomeIcon icon={faCalendarDays} /> Data Inicio</label>
                            <input type="date" className="form-control" id="inputDataInicio" name="dataInicio" value={filtro.dataInicio || ""} onChange={handleChange} />
                        </div>
                        <div className="col-6 col-sm-3 col-lg-2">
                            <label htmlFor="inputDataFim" className="form-label"><FontAwesomeIcon icon={faCalendarDays} /> Data Fim</label>
                            <input type="date" className="form-control" id="inputDataFim" name="dataFim" value={filtro.dataFim || ""} onChange={handleChange} />
                        </div>

                        <div className="col-6 col-sm-3 col-md-3">
                            <label htmlFor="inputVendedor" className="form-label"><FontAwesomeIcon icon={faClockRotateLeft} /> Status</label>
                            <select id="inputVendedor" className="form-select" name="status" value={filtro.status || ""} onChange={handleChange} >
                                <option value="" >Todas</option>
                                <option value="0">Abertas</option>
                                <option value="1">Finalizadas</option>
                                <option value="2">Rejeitadas</option>
                            </select>
                        </div>
                        <div className="col-6 col-sm-3 col-md-3">
                            <label htmlFor="inputVendedor" className="form-label"><FontAwesomeIcon icon={faUser} /> Vendedor</label>
                            <select id="inputVendedor" className="form-select" name="vendedor" onChange={handleChange} value={filtro?.vendedor} >
                                <option value="">Selecione...</option>
                                {
                                    vendedores?.map((vendedor, idx) => (
                                        <option value={vendedor.idUsuarioFranquia} key={vendedor.idUsuarioFranquia}  >{vendedor.NomeUsuario}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-2 d-flex align-items-center">
                            <button className='btn btn-primary mt-4' data-bs-toggle="modal" data-bs-target="#modalGraficoVendas"><FontAwesomeIcon icon={faChartSimple} /> Ver gráficos</button>
                        </div>
                    </div>
                </div>
            </div>

            <CardAutorizacaoClientes clientesFiltrado={clientesFiltrado} handleShowModal={handleShowModal} />

            {/*<div className="card m-2 shadow-lg rounded">*/}
            {/*    <GraficoProdutosVendedor />*/}
            {/*</div>*/}

            <ModalEditar
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                clienteEditar={clienteSelecionado}
                usuarioLogado={user}
            />
            <ModalGraficoVendas clientesFiltradoVendas={clientesFiltrado} />
        </div>
    );
};

export default AutorizacaoClientes;
