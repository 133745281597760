import React, { useEffect, useState } from 'react';
import CardAutorizacaoClientes from './CardAutorizacaoClientes';
import { userUtil } from '../utils/userUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faClockRotateLeft, faUser } from '@fortawesome/free-solid-svg-icons';
import ModalEditarCliente from './ModalEditarCliente';

const ModalEditarVendasPendentes = () => {
    const [clientes, setClientes] = useState([]);
    const [clientesFiltrado, setClientesFiltrado] = useState([]);
    const [user, setUser] = useState(null);

    const [vendedores, setVendedores] = useState([]);
    const [clienteSelecionado, setclienteSelecionado] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [filtro, setFiltro] = useState({ status: '0' });

    const handleShowModal = async (clientesel) => {
        setclienteSelecionado(clientesel);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setclienteSelecionado(null);
        setShowModal(false);
    };

    useEffect(() => {
        if (showModal) {
            document.querySelector("#fechar").click();
        }
    }, [showModal]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFiltro({ ...filtro, [name]: value.toUpperCase() });
    };

    useEffect(() => {
        setUser(userUtil.retornarUsuarioAtual());
    }, []);

    useEffect(() => {
        if (showModal) return;
        const fetchClientes = async () => {
            try {
                if (user?.idCaracteristica == null) return;

                const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/GetAutorizacaoCliente');

                if (!response.ok) {
                    throw new Error('Erro ao carregar clientes');
                }

                const data = await response.json();

                setClientes(data);
                setClientesFiltrado(data);
            } catch (error) {
                console.error('Erro ao carregar clientes:', error);
            }
        };

        const fetchVendedores = async () => {
            try {
                const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/GetFranquiados');

                if (!response.ok) {
                    throw new Error('Erro ao carregar clientes');
                }
                const data = await response.json();
                setVendedores(data);
            } catch (e) {
                console.error('Erro ao carregar vendedores:', e);
            }
        };

        fetchVendedores();
        fetchClientes();
    }, [user, showModal]); // Dependência adicionada

    useEffect(() => {
        const fetchFiltrar = async () => {
            var cli = clientes?.filter((cli) => {
                const dataConsideradaParaFiltro = cli.Status === 1 ? cli.DataAlteracao : cli.DataCadastro;
                const data = new Date(dataConsideradaParaFiltro);

                const ano = data.getFullYear();
                const mes = (data.getMonth() + 1).toString().padStart(2, '0');
                const dia = data.getDate().toString().padStart(2, '0');

                const dataFormatada = `${ano}-${mes}-${dia}`;

                return (
                    (!filtro.dataInicio || dataFormatada >= filtro.dataInicio) &&
                    (!filtro.dataFim || dataFormatada <= filtro.dataFim) &&
                    cli.Status === 0 &&
                    cli.idUsuarioFranquia === user.idUsuarioFranquia
                );
            });

            setClientesFiltrado(cli);
        };
        fetchFiltrar();
    }, [filtro, clientes]);


    return (
        <>
            <div class="modal fade" id="modalEditarVendasPendentes" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1" >
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Vendas pendentes</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="card m-2 shadow-lg rounded bg-dark">
                                <div className="card-header p-3">
                                    <div className="row text-white">
                                        <div className="col-6 col-sm-3 col-lg-2">
                                            <label htmlFor="inputDataInicio" className="form-label"><FontAwesomeIcon icon={faCalendarDays} /> Data Inicio</label>
                                            <input type="date" className="form-control" id="inputDataInicio" name="dataInicio" value={filtro.dataInicio || ""} onChange={handleChange} />
                                        </div>
                                        <div className="col-6 col-sm-3 col-lg-2">
                                            <label htmlFor="inputDataFim" className="form-label"><FontAwesomeIcon icon={faCalendarDays} /> Data Fim</label>
                                            <input type="date" className="form-control" id="inputDataFim" name="dataFim" value={filtro.dataFim || ""} onChange={handleChange} />
                                        </div>
                                        <div className="col-6 col-sm-3 col-md-3">
                                            <label htmlFor="inputVendedor" className="form-label"><FontAwesomeIcon icon={faClockRotateLeft} /> Status</label>
                                            <select id="inputVendedor" className="form-select" name="status" disabled>
                                                <option selected={true}>Abertas</option>
                                            </select>
                                        </div>
                                        <div className="col-6 col-sm-3 col-md-3">
                                            <label htmlFor="inputVendedor" className="form-label"><FontAwesomeIcon icon={faUser} /> Vendedor</label>
                                            <select id="inputVendedor" className="form-select" disabled >
                                                <option value="">{user?.NomeUsuario}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {((clientesFiltrado?.length ?? 0) > 0) && (
                                <CardAutorizacaoClientes clientesFiltrado={clientesFiltrado} handleShowModal={handleShowModal} />
                            )}

                            {((clientesFiltrado?.length ?? 0) == 0) && (
                                <h5 className='text-muted text-center pt-5'>Não existem vendas pendentes</h5>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="fechar" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                        </div>
                    </div>
                </div>
            </div>
            <ModalEditarCliente
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                clienteEditar={clienteSelecionado}
                usuarioLogado={user}
                editandoVendaEmAberto={true}
            />
        </>
    )
}

export default ModalEditarVendasPendentes;