import axios from "axios";

const apiMonitor = axios.create({
  baseURL: "https://monitor.rcasistemas.com.br"
  // baseURL: "http://127.0.0.1:8080"
});

apiMonitor.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers
  };

  return config;
});

export default apiMonitor;