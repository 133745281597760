import { toast } from "react-toastify";

const validarSenhaMaster = () => {
    const senhaMasterInput = document.querySelector("#senhaMasterInput")?.value ?? null;
    const valido = senhaMasterInput == calcularSenhaMaster();

    if (!valido) {
        toast.error("Senha master incorreta!");
        return;
    }

    document.querySelector("#senhaMaster").classList.add("d-none");

    const user = retornarUsuarioAtual();

    document.querySelector("#nomeUsuario").value = user?.NomeUsuario;
    document.querySelector("#senhaUsuario").value = user?.Senha;
    document.querySelector("#btnConfirmarSenha").classList.add("d-none")

    document.querySelector("#infoUsuario").classList.remove("d-none");
}

const verSenhaMaster = () => {
    if ((document.querySelector("#cnpjInformar")?.value ?? '').length == 0) {
        toast.error("Informe o CNPJ");
        return;
    }

    var senhaMaster = calcularSenhaMaster();

    document.querySelector("#informarCodigoParaVerSenhaMaster").classList.add("d-none");
    document.querySelector("#senhaMasterParaVisualizar").value = senhaMaster;
    document.querySelector("#divSenhaMaster").classList.remove("d-none");
}

const retornarUsuarioAtual = () => {
    return JSON.parse(localStorage.getItem('user') ?? "{}") || null;
}


const calcularSenhaMaster = () => {
    const now = new Date();

    const v1 = now.getDate();
    const v2 = now.getMonth() + 1;
    const v3 = now.getFullYear();
    const v4 = now.getHours();

    const senhaCorreta = Number(((v1 + v2 * v3 + v4 * v4) * (v1 + v2 * v3 + v4 * v4) / 24).toFixed(0));

    return senhaCorreta.toString();
}

export const userUtil = {
    validarSenhaMaster,
    retornarUsuarioAtual,
    calcularSenhaMaster,
    verSenhaMaster
}