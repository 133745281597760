import { format } from 'date-fns';
import React from 'react';
import imgPendente from '../images/Pendente50x50.png';
import imgAutorizado from '../images/Aprovado50x50.png';
import imgRejeitado from '../images/Rejeitado50x50.png';

const CardAutorizacaoClientes = ({ clientesFiltrado, handleShowModal }) => {
    return (
        <div className="card m-2 shadow-lg rounded">
            <div className="scrollbar-500 scrollbar-black bordered-black square">
                <table className="table table-hover table-striped ">
                    <thead className="table-dark">
                        <tr>
                            <th scope="col">Data</th>
                            <th scope="col">Data alteração</th>
                            <th scope="col">Status</th>
                            <th scope="col">Vendedor</th>
                            <th scope="col">Razão Social</th>
                            <th scope="col">Fantasia</th>
                            <th scope="col">Cnpj</th>
                            <th scope="col">Cidade</th>
                            <th scope="col">Bairro</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientesFiltrado?.map((cliente) => (
                            <tr className={`${(cliente.Status == 1) ? 'table-success' : ''} ${(cliente.Status == 2) ? 'table-danger' : ''} `} key={cliente.idAutorizacaoCliente} onClick={() => handleShowModal(cliente)}>
                                <td className="text-truncate" style={{ "maxWidth": "30px" }}>{format(Date.parse(cliente.DataCadastro), 'dd/MM/yyyy')}</td>
                                <td className="text-truncate" style={{ "maxWidth": "30px" }}>{format(Date.parse(cliente.DataAlteracao), 'dd/MM/yyyy')}</td>
                                <td className="text-truncate" style={{ "maxWidth": "10px" }}><img src={cliente.Status == 0 ? imgPendente : (cliente.Status == 1 ? imgAutorizado : imgRejeitado)} alt="{cliente.Status}" style={{ maxWidth: '30px', marginRight: '30px' }} /></td>
                                <td className="text-truncate" style={{ "maxWidth": "100px" }}>{cliente.NmUsuario?.trim()?.substring(0, 20)}</td>
                                <td className="text-truncate" style={{ "maxWidth": "100px" }}>{cliente.NmPessoa.trim().substring(0, 20)}</td>
                                <td className="text-truncate" style={{ "maxWidth": "100px" }}>{cliente.NmCurto.trim().substring(0, 15)}</td>
                                <td className="text-truncate">{cliente.CdCPF_CGC}</td>
                                <td className="text-truncate" style={{ "maxWidth": "50px" }}>{cliente.NomeCidade.trim()} - {cliente.UF}</td>
                                <td className="text-truncate" style={{ "maxWidth": "50px" }}>{cliente.NomeBairro.trim()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default CardAutorizacaoClientes;