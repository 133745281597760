export const validarCNPJ = (cnpj) => {
    if (cnpj == "" || cnpj == null) return false;

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;
    if (cnpj.length != 14) return false;

    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;

    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1)) return false;

    return true;
}

export const validarNumeros = (valor, tamanhoEsperado) => {
    return valor.length === tamanhoEsperado && /^\d+$/.test(valor);
};

export const validarTexto = (texto, tamanhoMaximo = 150) => {
    return texto?.trim().length > 0 && texto?.trim().length <= tamanhoMaximo;
};

export const validarEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
};

export const validarTelefone = (telefone) => {
    const regex = /^\(\d{2}\) ?\d{4} \d{4}$/;
    return regex.test(telefone);
};

export const validarCEP = (cep) => {
    const re = /^\d{5}-\d{3}$/;
    return re.test(cep);
};

export const formatarTelefone = (telefone) => {
    if (!telefone) return '';
    const numeros = telefone.replace(/\D/g, '');
    if (numeros.length === 8) {
        return `(${numeros.substring(0, 2)}) ${numeros.substring(2, 6)} ${numeros.substring(6, 8)}`;
    }
    return telefone;
};

export const formatarCEP = (cep) => {
    if (!cep) return '';
    const numeros = cep.replace(/\D/g, '');
    if (numeros.length === 8) {
        return `${numeros.substring(0, 5)}-${numeros.substring(5, 8)}`;
    }
    return cep;
};

export const removerAspas = (texto) => {
    return texto?.replace(/['"]/g, '');
}

export const extrairNumeros = (str) => {
    return str.replace(/\D/g, ''); // Substitui todos os caracteres não numéricos por uma string vazia
}

export const getDataPrimeiroDiaMes = () => {
    const today = new Date();
    
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = '01'; // First day of the month
    
    return `${year}-${month}-${day}`;
}

export const formatarData = (data) => {
    const year = data.getFullYear();
    const month = String(data.getMonth() + 1).padStart(2, '0'); // Meses são baseados em zero, então somamos 1
    const day = String(data.getDate()).padStart(2, '0'); // Garantir que o dia tenha dois dígitos

    return `${year}-${month}-${day}`;
}