import apiMonitor from "./apiMonitor"

const liberarVersao = (data) => {
    try {
        return apiMonitor.post("/Versao/Liberar", data, {
            headers: {
                'key': 'yZEyE8Rk5jLE23qP',
                'mode': 'no-cors'
            }
        })
    } catch(error){
        throw error;
    }
    
}

export const monitorService = {
    liberarVersao
}