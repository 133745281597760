export const requestOptionsPOST = (data, cors = true, key = true) => {
    var requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Accept-Encoding": "gzip, deflate, br",
            "Accept": "*/*",
            "key": key ? "yZEyE8Rk5jLE23qP" : null
        },
        body: JSON.stringify(data),
        redirect: 'follow'
    };

    if (!cors) {
        requestOptions.mode = "no-cors";
    }

    return requestOptions;
}

export const requestOptionsPUT = (data) => {
    var requestOptions = {
        method: 'PUT',
        headers: {
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(data),
        redirect: 'follow'
    };

    return requestOptions;
}

export const requestOptionsGET = (cors = true) => {
    var requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": 'application/json'
        }
    };

    if (!cors) {
        requestOptions.mode = "no-cors";
    }

    return requestOptions;
}