import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalDetalheCliente from '../components/ModalDetalheCliente';
import { faClipboardUser, faCity } from '@fortawesome/free-solid-svg-icons';
import ModalEditarVendasPendentes from '../components/ModalEditarVendasPendentes';

const Clientes = ({ user }) => {
    const [clientes, setClientes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [clienteSelecionada, setclienteSelecionada] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [pagina, setPagina] = useState(1);
    const [filtrarDesativados, setfiltrarDesativados] = useState(false);
    const [filtrarBloqueados, setfiltrarBloqueados] = useState(false);
    const [filtro, setFiltro] = useState({ cliente: '', bairro: '', cidade: '' });
    const [clientesFiltrado, setClientesFiltrado] = useState([]);

    const handleShowModal = (clientesel) => {
        setclienteSelecionada(clientesel);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFiltro(f => ({ ...f, [name]: value.toUpperCase() }));
    };

    useEffect(() => {
        const fetchClientes = async () => {
            if (user?.idCaracteristica == null) return;

            setClientes(null);

            const dataEnv = {
                idCaracteristica: user.idCaracteristica,
                pesquisar: filtro.cliente,
                filtrarDesativados,
                filtrarBloqueados,
                cidade: filtro.cidade,
                bairro: filtro.bairro,
                pagina: pagina,
                porPagina: 13
            };

            var requestOptions = {
                method: 'GET',
                headers: { "Content-Type": 'application/json' }
            };

            const queryString = new URLSearchParams(dataEnv).toString();
            //const response = await fetch('http://localhost:63299/franquia/GetClientes?' + queryString, requestOptions);
            const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/GetClientes?' + queryString, requestOptions);

            if (!response.ok) {
                throw new Error('Erro ao carregar clientes');
            }

            const data = await response.json();

            setClientes(data);
        };

        fetchClientes();
    }, [searchTerm, filtrarDesativados, filtrarBloqueados, user, pagina, filtro.cidade, filtro.bairro, filtro.cliente]);

    useEffect(() => {
        const fetchFiltrar = async () => {
            var cli = clientes?.Clientes?.sort((a, b) => Number(b.Codigo) - Number(a.Codigo))?.filter(cli => {
                return (!filtro.cliente || cli.Nome.toUpperCase().includes(filtro.cliente) || cli.NomeCurto.toUpperCase().includes(filtro.cliente) || cli.Codigo.trim().toUpperCase().includes(filtro.cliente)) &&
                    (!filtro.cidade || cli.Cidade.toUpperCase().includes(filtro.cidade)) &&
                    (!filtro.bairro || cli.Bairro.toUpperCase().includes(filtro.bairro));
            });

            setClientesFiltrado(cli);
        };
        fetchFiltrar();
    }, [filtro, clientes]);

    return (
        <div>
            {/*<CardPesquisaCliente user={user} filtrarBloqueados={filtrarBloqueados} setfiltrarBloqueados={setfiltrarBloqueados} filtrarDesativados={filtrarDesativados} setfiltrarDesativados={setfiltrarDesativados} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />*/}
            <div className="card-deck">
                <div className="card mb-1 shadow-lg rounded bg-dark">
                    <div className="card-header p-3">
                        <div className="row text-white">
                            <div className="col-12 col-md-4">
                                <label htmlFor="inputCliente" className="form-label"><FontAwesomeIcon icon={faClipboardUser} /> Cliente</label>
                                <input placeholder='Nome do cliente' type="text" className="form-control" id="inputCliente" name="cliente" value={filtro.cliente || ""} onChange={handleChange} />
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <label htmlFor="inputVendedor" className="form-label"><FontAwesomeIcon icon={faCity} /> Cidade</label>
                                <input placeholder='Nome da cidade' className='form-control' value={filtro.cidade} name="cidade" type='text' onChange={handleChange} />
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <label htmlFor="inputVendedor" className="form-label"><FontAwesomeIcon icon={faCity} /> Bairro</label>
                                <input placeholder='Nome do bairro' className='form-control' value={filtro.bairro} name="bairro" type='text' onChange={handleChange} />
                            </div>
                            {
                                (user?.Perfil == 3) && (
                                    <div className="col-6 col-sm-6 col-md-2 d-flex justify-content-center align-items-center">
                                        <button className='btn btn-primary mt-2 mt-md-4' data-bs-toggle="modal" data-bs-target="#modalEditarVendasPendentes">
                                            <i className="fa-solid fa-user-group"></i> Clientes pendentes
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="card m-0 shadow-lg rounded" >
                {(clientes?.Clientes != null)
                    ?
                    <>
                        <div className="table-responsive rounded">
                            <table className="table table-hover table-striped">
                                <thead className="table-dark">
                                    <tr >
                                        <th scope="col">Codigo</th>
                                        <th scope="col">Razão</th>
                                        <th scope="col">Fantasia</th>
                                        <th scope="col">CNPJ</th>
                                        <th scope="col">Cidade</th>
                                        <th scope="col">Bairro</th>
                                        <th scope="col">UF</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {clientesFiltrado?.map((cliente) => (
                                        <tr key={cliente.idPessoa + (cliente.Nome ?? 'NA')} className={`${(cliente.Desativado == true) ? 'table-secondary' : ''} ${(cliente.Bloqueado == true) ? 'table-danger' : ''} ${(cliente.EmAtraso == true) ? 'table-warning' : ''} `} onClick={() => handleShowModal(cliente)}>
                                            <td className="text-truncate" scope="row">{cliente.Codigo?.trim()}</td>
                                            <td className="text-truncate" style={{ "maxWidth": "150px" }}>{cliente.Nome?.trim()}</td>
                                            <td className="text-truncate" style={{ "maxWidth": "150px" }}>{cliente.NomeCurto?.trim()}</td>
                                            <td className="text-truncate">{cliente.Cnpj?.trim()}</td>
                                            <td className="text-truncate" style={{ "maxWidth": "100px" }}>{cliente.Cidade?.trim()}</td>
                                            <td className="text-truncate" style={{ "maxWidth": "100px" }}>{cliente.Bairro?.trim()}</td>
                                            <td className="text-truncate">{cliente.Uf?.trim()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {
                            (clientes.TotalPagina > 1)
                                ? <nav aria-label="PageClientes" className="p-1">
                                    <ul className="pagination ">
                                        <li className="page-item">
                                            <a className={`page-link ${(pagina == 1) ? 'disabled' : ''}`} onClick={() => setPagina(pagina - 1)} aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>

                                        {(() => {
                                            const elements = [];
                                            const totPage = (clientes.TotalPagina >= 10) ? 10 : clientes.TotalPagina + 1;
                                            //console.log(totPage);
                                            for (let i = 1; i < totPage; i++) {
                                                elements.push(<li key={i} className={`page-item ${(i == clientes.pagina) ? 'active' : ''}`}><a className="page-link" onClick={() => setPagina(i)}>{i}</a></li>);
                                            }
                                            return elements;
                                        })()}

                                        <li className="page-item">
                                            <a className="page-link" onClick={() => setPagina(pagina + 1)} aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                                : ""
                        }

                    </>
                    : <div className="table-responsive rounded">
                        <table className="table table-hover table-striped">
                            <thead className="table-dark">
                                <tr>
                                    <th scope="col">Codigo</th>
                                    <th scope="col">Razão</th>
                                    <th scope="col">Fantasia</th>
                                    <th scope="col">CNPJ</th>
                                    <th scope="col">Cidade</th>
                                    <th scope="col">Uf</th>
                                </tr>
                            </thead>
                            <tbody >
                                <tr>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                </tr>
                                <tr>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                </tr>
                                <tr>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                </tr>
                                <tr>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                </tr>
                                <tr>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                </tr>
                                <tr>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                </tr>
                                <tr>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                </tr>
                                <tr>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                </tr>
                                <tr>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                    <td className="text-truncate"><span className="placeholder col-12"></span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
            </div>

            <ModalDetalheCliente
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                clienteSelecionado={clienteSelecionada}
            />

            <ModalEditarVendasPendentes />
        </div >
    );
};

export default Clientes;
