import { React, useState, useEffect } from 'react';

const ModalNovoFranquiado = ({ showModal, handleCloseModal, Caracteristicas, handleSalvarUsuario, usuarioSelecionado, usuariosBimer }) => {
    const [formData, setFormData] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        const formDataEditado = { ...formData, [name]: value.toUpperCase() };
        setFormData(formDataEditado);
    };

    useEffect(() => {
        const validarUsuarioSelecionado = async () => {
            if (usuarioSelecionado != null) {
                // console.log(usuarioSelecionado)
                // console.log(usuariosBimer?.filter(x => x.IdUsuario == usuarioSelecionado?.idUsuario));

                setFormData(usuarioSelecionado);
            }
        };

        validarUsuarioSelecionado();
    }, [usuarioSelecionado]);

    return (
        <div>
            {showModal && (
                <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header text-white bg-dark">
                                <h5 className="modal-title">{(usuarioSelecionado?.idUsuarioFranquia == null) ? 'Novo' : 'Editando'} Franquiado</h5>
                                <button type="button" className="btn-close btn-close-white" onClick={handleCloseModal}>
                                    {/*<span aria-hidden="true">&times;</span>*/}
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <label htmlFor="inputNome" className="form-label">Nome</label>
                                        <input type="text" autoComplete="off" className="form-control " id="inputNome" name="NomeUsuario" onChange={handleChange} value={formData.NomeUsuario || ''} />
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <label htmlFor="inputSenhaUser" className="form-label">Senha</label>
                                        <input type="password" autoComplete="off" className="form-control " id="inputSenhaUser" name="Senha" onChange={handleChange} value={formData.Senha || ''} />
                                    </div>

                                    <div className="col-12 col-sm-6">
                                        <label htmlFor="selectPrrfil" className="form-label">Perfil</label>
                                        <select className="form-select form-select-sm" id="selectPrrfil" aria-label=".form-select-sm example" name="Perfil" onChange={handleChange} value={formData.Perfil || 0}>
                                            <option>Selecione</option>
                                            <option value="1">Administrador RCA</option>
                                            <option value="2">Franqueado</option>
                                            <option value="3">Vendedor</option>
                                            <option value="4">Administrador Vendedor</option>
                                            <option value="5">Revenda</option>
                                        </select>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <label htmlFor="selectCarac" className="form-label">Características</label>
                                        <select className="form-select form-select-sm" id="selectCarac" aria-label=".form-select-sm caract" name="idCaracteristica" onChange={handleChange} value={formData.idCaracteristica || ''}>
                                            <option>Selecione</option>
                                            {
                                                Caracteristicas.map((carac) => (
                                                    <option key={carac.IdCaracteristicaPessoa} value={carac.IdCaracteristicaPessoa}>
                                                        {carac.NmCaracteristicaPessoa}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    {
                                        ((formData?.Perfil == 2 || formData?.Perfil == 5))
                                            ? <div className="col-12 col-sm-6">
                                                <label htmlFor="selectCaracBloq" className="form-label">Características Bloqueio</label>
                                                <select className="form-select form-select-sm" id="selectCaracBloq" aria-label=".form-select-sm caract" name="idCaracteristicaBloqueio" onChange={handleChange} value={formData.idCaracteristicaBloqueio || ''}>
                                                    <option>Selecione</option>
                                                    {
                                                        Caracteristicas.map((carac) => (
                                                            <option key={carac.IdCaracteristicaPessoa} value={carac.IdCaracteristicaPessoa}>
                                                                {carac.NmCaracteristicaPessoa}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            : ''
                                    }
                                    {
                                        (formData.Perfil >= 2)
                                            ? <div className="col-12 col-sm-6">
                                                <label htmlFor="selectIdUsuario" className="form-label">Usuario Bimer</label>
                                                <select className="form-select form-select-sm" id="selectIdUsuario" aria-label=".form-select-sm caract" name="idUsuario" onChange={handleChange} value={formData.idUsuario || ''}>
                                                    <option>Selecione</option>
                                                    {
                                                        usuariosBimer.map((user) => (
                                                            <option key={user.IdUsuario} value={user.IdUsuario}>
                                                                {user.NmPessoa}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            : ''
                                    }
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success" onClick={() => { handleSalvarUsuario(formData) }}>
                                    Salvar
                                </button>
                                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                                    Fechar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ModalNovoFranquiado;