import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

const prepararDadosGraficoProdutosVendidos = (vendas) => {
    const todosOsProdutos = [];

    vendas.forEach(venda => {
        const produtos = JSON.parse(venda.Objeto || "{}").produtomodel || [];
        produtos.forEach(produto => {
            todosOsProdutos.push(produto);
        });
    });

    const valoresPorProduto = {};

    todosOsProdutos.forEach(produto => {
        const { NmProduto } = produto;

        if (!valoresPorProduto[NmProduto]) {
            valoresPorProduto[NmProduto] = 1;
        } else {
            valoresPorProduto[NmProduto] += 1;
        }
    });

    const data = {
        labels: Object.keys(valoresPorProduto),
        datasets: [
            {
                label: 'Quantidade de Produtos Vendidos',
                backgroundColor: 'rgba(255, 159, 64, 0.2)', // Cor laranja com transparência
                borderColor: 'rgba(255, 159, 64, 1)', // Cor laranja
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255, 159, 64, 0.4)', // Cor laranja mais clara ao passar o mouse
                hoverBorderColor: 'rgba(255, 159, 64, 1)', // Cor laranja ao passar o mouse
                data: Object.values(valoresPorProduto) // Quantidade de cada produto vendido
            }
        ]
    };

    const options = {
        maintainAspectRatio: false,
        scales: {
            x: { stacked: true },
            y: { stacked: true }
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                    return datasetLabel + ': ' + vendasAgrupadas[data.labels[tooltipItem.index]].nomeCompleto;
                }
            }
        },
        maxBarThickness: 50 // Define o tamanho máximo da barra
    };

    return { data, options };
}

const exibirDadosGraficoProdutosVendidos = (vendas) => {
    const { data, options } = prepararDadosGraficoProdutosVendidos(vendas);

    return (
        <div style={{ width: '100%', height: '400px' }}>
            <Bar data={data} options={options} />
        </div>
    );
}


const prepararDadosGraficoVendas = (vendas) => {
    const vendasAgrupadas = vendas.reduce((acc, venda) => {
        const { NmUsuario, Objeto } = venda;
        const vendaObjeto = JSON.parse(Objeto || "{}");

        if (!acc[NmUsuario]) {
            acc[NmUsuario] = {
                vendedor: NmUsuario,
                ValorImplantacao: Number(vendaObjeto?.ValorImplantacao) || 0,
                VlTitulo: vendaObjeto?.produtomodel?.reduce((acc, cur) => acc += cur.VlProduto, 0),
                quantidadeVendas: 1
            };
        } else {
            acc[NmUsuario].ValorImplantacao += Number(vendaObjeto.ValorImplantacao) || 0;
            acc[NmUsuario].VlTitulo += vendaObjeto?.produtomodel?.reduce((acc, cur) => acc += cur?.VlProduto, 0);
            acc[NmUsuario].quantidadeVendas++;
        }

        return acc;
    }, {});

    const data = {
        labels: [],
        datasets: [
            {
                label: 'Quantidade de Vendas',
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(75,192,192,0.4)',
                hoverBorderColor: 'rgba(75,192,192,1)',
                data: []
            },
            {
                label: 'Valor de Implantação',
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: []
            },
            {
                label: 'Valor Mensalidade',
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(54, 162, 235, 0.4)',
                hoverBorderColor: 'rgba(54, 162, 235, 1)',
                data: []
            }
        ]
    };

    // const numeros = ['12001200600000', '6010.000005000000', '10-10100000000000', '12001200600000', '106244.8170010001412-6011501150001000141201412'];

    Object.values(vendasAgrupadas).forEach(item => {
        if (item.vendedor) {
            data.labels.push(item.vendedor);
            const quantidadeVendas = Number(item?.quantidadeVendas);
            const valorImplantacao = Number(Number(item?.ValorImplantacao ?? 0)?.toFixed(2));
            const vlTitulo = Number(Number(item?.VlTitulo ?? 0)?.toFixed(2));

            // if (numeros.includes(item?.ValorImplantacao)) {
            //     console.log(item);
            // }

            data.datasets[0].data.push(quantidadeVendas);
            data.datasets[1].data.push(valorImplantacao);
            data.datasets[2].data.push(vlTitulo);
        }
    });

    const options = {
        maintainAspectRatio: false,
        scales: {
            x: { stacked: true },
            y: { stacked: true }
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                    return datasetLabel + ': ' + vendasAgrupadas[data.labels[tooltipItem.index]].nomeCompleto;
                }
            }
        },
        maxBarThickness: 50 // Define o tamanho máximo da barra
    };

    return { data, options };
}

const exibirGraficoVendasQuantidadeVendas = (vendas) => {
    const { data, options } = prepararDadosGraficoVendas(vendas);

    const graficoVendas = data.datasets[0];
    data.datasets = [];
    data.datasets.push(graficoVendas);

    return (
        <div style={{ width: '100%', height: '400px' }}>
            <Bar data={data} options={options} />
        </div>
    );
}

const exibirGraficoVendasValorImplantacao = (vendas) => {
    const { data, options } = prepararDadosGraficoVendas(vendas);

    const graficoVendas = data.datasets[1];

    data.datasets = [];
    data.datasets.push(graficoVendas);

    return (
        <div style={{ width: '100%', height: '400px' }}>
            <Bar data={data} options={options} />
        </div>
    );
}

const exibirGraficoVendasValorMensalidade = (vendas) => {
    const { data, options } = prepararDadosGraficoVendas(vendas);

    const graficoVendas = data.datasets[2];
    data.datasets = [];
    data.datasets.push(graficoVendas);

    return (
        <div style={{ width: '100%', height: '400px' }}>
            <Bar data={data} options={options} />
        </div>
    );
}

export {
    exibirGraficoVendasQuantidadeVendas,
    exibirGraficoVendasValorImplantacao,
    exibirGraficoVendasValorMensalidade,
    prepararDadosGraficoProdutosVendidos,
    exibirDadosGraficoProdutosVendidos
}