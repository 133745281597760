import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHotel, faLocationDot, faAddressBook, faBoxOpen, faTicket, faDoorClosed, faUser, faCodeCompare, faCodeCommit, faPlus, faCancel, faCheck, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { userUtil } from '../utils/userUtil';
import { extrairNumeros, formatarData } from '../utils/Validadores';
import { toast } from 'react-toastify';
import { monitorService } from '../api/monitorService';
let devEnvironment = window.location.host?.includes(':3000');
// devEnvironment = false;


const ModalDetalheCliente = ({ showModal, handleCloseModal, clienteSelecionado }) => {
    const [historicoVersoesCliente, setHistoricoVersoesCliente] = useState([]);
    const [versoesSistemaFarma, setversoesSistemaFarma] = useState([]);
    const [versoesSistemaGPC, setversoesSistemaGPC] = useState([]);
    const [versoesSistemaAgente, setversoesSistemaAgente] = useState([]);
    const [versoesSistemaFarmaWeb, setversoesSistemaFarmaWeb] = useState([]);
    const [confirmarLiberarVersao, setConfirmarLiberarVersao] = useState(false);
    const [versaoLiberar, setVersaoLiberar] = useState(null);
    const [exibirVersoes, setExibirVersoes] = useState(false);

    const handleBloquarFranquiado = async () => {

        var requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify(clienteSelecionado),
            redirect: 'follow'
        };

        //const response = await fetch('http://localhost:63299/franquia/PostBloquearFranquiado', requestOptions);
        const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/PostBloquearFranquiado', requestOptions);

        if (!response.ok) {
            throw new Error('Erro ao enviar o formulário ');
        }

        handleCloseModal();

        window.location.reload();
    };

    const handleLiberarFranquiado = async () => {

        var requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify(clienteSelecionado),
            redirect: 'follow'
        };

        //const response = await fetch('http://localhost:63299/franquia/PostDesbloquearFranquiado', requestOptions);
        const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/PostDesbloquearFranquiado', requestOptions);

        if (!response.ok) {
            throw new Error('Erro ao enviar o formulário ');
        }

        handleCloseModal();

        window.location.reload();
    };


    const isDowngrade = (versaoAlvo, ultimaVersao) => {
        const getLastTwoSegments = (version) => {
            const segments = version?.split(".");
            return segments.slice(-2).map(Number);
        };

        const [majorA, minorA] = getLastTwoSegments(versaoAlvo);
        const [majorB, minorB] = getLastTwoSegments(ultimaVersao);

        if (majorA !== majorB) {
            return majorA < majorB;
        }

        return minorA <= minorB;
    }


    const liberarVersao = (versao) => {
        // devEnvironment = true;
        const nomePrograma = getNomePrograma(versao.pacote);

        const versaoLiberadaAnteriormente = historicoVersoesCliente.filter(v => v.pacote?.toUpperCase()?.includes(nomePrograma))?.find(v => v.versao == versao.versao) ?? null;

        if (versaoLiberadaAnteriormente != null) {
            toast.warn(`A versão ${versao?.versao} já foi liberada anteriormente`);
            return;
        }

        //considerando que o servidor já ordenou
        let latestVersion = null;

        switch (nomePrograma) {
            case "FARMA":
                latestVersion = versoesSistemaFarma[0]
                break;
            case "RCAGPC":
                latestVersion = versoesSistemaGPC[0]
                break;
            case "FarmaProWeb":
                latestVersion = versoesSistemaFarmaWeb[0]
                break;
            case "AGENTE":
                latestVersion = versoesSistemaAgente[0]
                break;
        }

        if (latestVersion != null) {
            if (nomePrograma == "FARMA") {
                if (isDowngrade(versao?.versao, latestVersion?.versao)) {
                    toast.warn(`Versão ${latestVersion?.versao} foi liberada. Não é possível fazer um downgrade para a versão ${versao?.versao}.`);
                    return;
                }
            }
        }

        const destino = extrairNumeros(clienteSelecionado?.Cnpj ?? '');

        const data = {
            destinos: [destino],
            obrigatorio: false,
            pacote: versao,
            producao: !devEnvironment,
            programa: nomePrograma,
            versao: versao?.versao
        }

        try {
            monitorService.liberarVersao(data).then(res => {
                if (res) {
                    toast.success(`Versão ${versao?.versao} liberada com sucesso`);
                    setConfirmarLiberarVersao(false);
                    buscarHistoricoVersoes();
                } else {
                    toast.error(`Erro ao liberar versão ${versao?.versao}`);
                    setConfirmarLiberarVersao(false);
                    buscarHistoricoVersoes();
                }
            }).catch(error => {
                console.log(error);
                toast.error(`Erro ao liberar versão ${versao?.versao}`);
                setConfirmarLiberarVersao(false);
                buscarHistoricoVersoes();
            });;
        } catch (error) {
            console.log(error)
            // toast.error(`Erro ao liberar versão ${versao?.versao}`);
            setConfirmarLiberarVersao(false);
            buscarHistoricoVersoes();
        }
    }

    const getNomePrograma = (pacote) => {
        if (pacote?.toUpperCase()?.includes("RCAGPC")) {
            return "RCAGPC";
        } else if (pacote?.toUpperCase()?.includes("FARMA_")) {
            return "FARMA"
        } else if (pacote?.toUpperCase()?.includes("FARMAPROWEB")) {
            return "FarmaProWeb"
        } else if (pacote?.toUpperCase()?.includes("AGENTE")) {
            return "AGENTE";
        } else {
            return pacote;
        }
    }

    const buscarHistoricoVersoes = () => {
        const dados = {
            destino: extrairNumeros(clienteSelecionado?.Cnpj ?? ''),
            producao: !devEnvironment,
            programa: null,
            dataInicio: '2000-01-01',
            dataFim: formatarData(new Date())
        };

        const params = `destino=${dados.destino}&producao=${dados.producao}&programa=${dados.programa}&dataInicio=${dados.dataInicio}&dataFim=${dados.dataFim}`;

        const fetchHistoricoVersoes = () => {
            try {
                const response = fetch(`https://monitor.rcasistemas.com.br/Versao/GetHistorico?${params}`);

                response.then(resp => {
                    resp.json().then(itens => {
                        setHistoricoVersoesCliente(itens ?? []);
                        fetchVersoesFarma();
                        fetchVersoesGPC();
                        fetchVersoesAgente();
                        fetchVersoesFarmaWeb();
                    })
                });

            } catch (error) {
                console.error("Erro ao buscar as versões do sistema:", error);
                setHistoricoVersoesCliente([]);
            }
        };

        const fetchVersoesFarma = () => {
            try {
                const response = fetch(`https://monitor.rcasistemas.com.br/Versao/GetVersoes?producao=${!devEnvironment}&programa=FARMA`);

                function manterPenultimaVersao(array) {
                    if (array.length <= 1) {
                        return [];
                    }

                    return [array[1]];
                }

                const programas = ["FarmaProWeb", "AGENTE", "RCAGPC"];

                response.then(resp => {
                    resp.json().then(itens => {
                        var filtrado = itens?.filter(x => x.pacote.includes('FARMA'));
                        var versoes = manterPenultimaVersao(filtrado);

                        itens = itens.filter(item => programas.find(prog => item.pacote?.includes(prog)) != null);

                        versoes = [...versoes, ...itens];

                        setversoesSistemaFarma(versoes ?? []);
                    });
                });

            } catch (error) {
                console.error("Erro ao buscar as versões do sistema:", error);
                setversoesSistemaFarma([]);
            }
        };

        const fetchVersoesGPC = () => {
            try {
                const response = fetch(`https://monitor.rcasistemas.com.br/Versao/GetVersoes?producao=${!devEnvironment}&programa=RCAGPC`);

                response.then(resp => {
                    resp.json().then(itens => {
                        setversoesSistemaGPC(itens ?? []);
                    });
                });

            } catch (error) {
                console.error("Erro ao buscar as versões do sistema:", error);
                setversoesSistemaGPC([]);
            }
        };

        const fetchVersoesAgente = () => {
            try {
                const response = fetch(`https://monitor.rcasistemas.com.br/Versao/GetVersoes?producao=${!devEnvironment}&programa=AGENTE`);

                response.then(resp => {
                    resp.json().then(itens => {
                        setversoesSistemaAgente(itens ?? []);
                    });
                });

            } catch (error) {
                console.error("Erro ao buscar as versões do sistema:", error);
                setversoesSistemaAgente([]);
            }
        };

        const fetchVersoesFarmaWeb = () => {
            try {
                const response = fetch(`https://monitor.rcasistemas.com.br/Versao/GetVersoes?producao=${!devEnvironment}&programa=FarmaProWeb`);

                response.then(resp => {
                    resp.json().then(itens => {
                        setversoesSistemaFarmaWeb(itens ?? []);
                    });
                });

            } catch (error) {
                console.error("Erro ao buscar as versões do sistema:", error);
                setversoesSistemaFarmaWeb([]);
            }
        };

        fetchHistoricoVersoes();
    }

    useEffect(() => {
        const user = userUtil.retornarUsuarioAtual();

        if ((user?.Perfil == 2 || user?.Perfil == 5) && clienteSelecionado) {
            setExibirVersoes(true);
            buscarHistoricoVersoes();
        }
    }, [clienteSelecionado]);

    return (
        <div>
            {showModal && (
                <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header text-white bg-dark">
                                <h5 className="modal-title"><FontAwesomeIcon icon={faUser} /> Cliente</h5>
                                <button type="button" className="btn-close btn-close-white" onClick={handleCloseModal}>
                                    {/*<span aria-hidden="true">&times;</span>*/}
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12 col-xl-6">
                                        <div className="card shadow mb-3">
                                            <div className="card-header text-white bg-dark">
                                                <FontAwesomeIcon icon={faHotel} /> Dados Empresa
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <label htmlFor="inputRazao" className="form-label">Razão Social</label>
                                                        <input type="text" className="form-control " disabled id="inputRazao" defaultValue={clienteSelecionado?.Nome?.trim()} />
                                                    </div>
                                                    <div className="col-12">
                                                        <label htmlFor="inputFantasia" className="form-label">Nome Fantasia</label>
                                                        <input type="text" className="form-control " disabled id="inputFantasia" defaultValue={clienteSelecionado?.NomeCurto} />
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <label htmlFor="inputCnpj" className="form-label">Cnpj</label>
                                                        <input type="text" className="form-control " disabled id="inputCnpj" defaultValue={clienteSelecionado.Cnpj} />
                                                    </div>
                                                    <div className="col-6 col-sm-6">
                                                        <label htmlFor="inputIe" className="form-label">Ie</label>
                                                        <input type="text" className="form-control " disabled id="inputIe" defaultValue={clienteSelecionado.Ie} />
                                                    </div>
                                                    <div className="col-6 col-sm-6">
                                                        <label htmlFor="inputIm" className="form-label">Im</label>
                                                        <input type="text" className="form-control " disabled id="inputIm" defaultValue={clienteSelecionado.Im} />
                                                    </div>
                                                    <div className="col-6 col-sm-6">
                                                        <label htmlFor="inputCRT" className="form-label">CRT</label>
                                                        <input type="text" className="form-control " disabled id="inputCRT" defaultValue={clienteSelecionado?.CRT} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-6">
                                        <div className="card shadow mb-3">
                                            <div className="card-header text-white bg-dark">
                                                <FontAwesomeIcon icon={faLocationDot} /> Endereço
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <label htmlFor="inputEndereco" className="form-label">Endereço</label>
                                                        <input type="text" className="form-control " disabled id="inputEndereco" defaultValue={clienteSelecionado?.Endereco?.trim()} />
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <label htmlFor="inputCidade" className="form-label">Cidade</label>
                                                        <input type="text" className="form-control " disabled id="inputCidade" defaultValue={clienteSelecionado?.Cidade?.trim()} />
                                                    </div>
                                                    {/*<div className="col-5 col-sm-3">*/}
                                                    {/*    <label htmlFor="inputCep" className="form-label">Cep</label>*/}
                                                    {/*    <input type="text" className="form-control " disabled id="inputCep" defaultValue={clienteSelecionado?.CdCEP?.trim()} />*/}
                                                    {/*</div>*/}
                                                    <div className="col-3 col-sm-2">
                                                        <label htmlFor="inputUf" className="form-label">Uf</label>
                                                        <input type="text" className="form-control" disabled id="inputUf" defaultValue={clienteSelecionado?.Uf?.trim()} />
                                                    </div>
                                                    <div className="col-5 col-sm-4">
                                                        <label htmlFor="inputBairro" className="form-label">Bairro</label>
                                                        <input type="text" className="form-control" disabled id="inputBairro" defaultValue={clienteSelecionado?.Bairro?.trim()} />
                                                    </div>
                                                    {/*<div className="col-5 col-sm-3">*/}
                                                    {/*    <label htmlFor="inputNumero" className="form-label">Nº</label>*/}
                                                    {/*    <input type="text" className="form-control" disabled id="inputNumero" defaultValue={clienteSelecionado?.NrLogradouro?.trim()} />*/}
                                                    {/*</div>*/}
                                                    {/*<div className="col-12">*/}
                                                    {/*    <label htmlFor="inputComplemento" className="form-label">Complemento</label>*/}
                                                    {/*    <input type="text" className="form-control" disabled id="inputComplemento" defaultValue={clienteSelecionado?.DsComplemento?.trim()} />*/}
                                                    {/*</div>*/}
                                                    {/*<div className="col-12">*/}
                                                    {/*    <label htmlFor="inputParecer" className="form-label">Parecer</label>*/}
                                                    {/*    <input type="text" className="form-control" disabled id="inputParecer" defaultValue={clienteSelecionado?.DsParecer?.trim()} />*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-6">
                                        <div className="card shadow mb-3">
                                            <div className="card-header text-white bg-dark">
                                                <FontAwesomeIcon icon={faAddressBook} /> Contato
                                            </div>
                                            <div className="card-body" style={{ height: '170px', overflow: 'auto' }}>
                                                <div className="table-responsive rounded" >
                                                    <table className="table">
                                                        <thead className="table-dark">
                                                            <tr>
                                                                <th scope="col">Tipo</th>
                                                                <th scope="col">Contato</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {clienteSelecionado.Contatos.map((contato) => (
                                                                <tr key={(contato.IdPessoa ?? contato.DSContato) + contato.NmTipoContato}>
                                                                    <td className="text-truncate">{contato.NmTipoContato}</td>
                                                                    <td className="text-truncate">{contato.DSContato}</td>
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-6">
                                        <div className="card shadow rounded" >
                                            <div className="card-header text-white bg-dark">
                                                <FontAwesomeIcon icon={faBoxOpen} /> Caracteristicas
                                            </div>
                                            <div className="card-body" style={{ height: '170px', overflow: 'auto' }}>
                                                <div className="align-items-center">
                                                    {clienteSelecionado && clienteSelecionado.Caracteristicas.map((carac) => (
                                                        <img key={carac.IdCaracteristicaPessoa} width="60px" height="60px" className="img-fluid rounded p-1" alt={carac.NmCaracteristicaPessoa} title={carac.NmCaracteristicaPessoa} src={'http://api.erp.rcasistemas.com.br/api/GetImgCarac?IdCaracteristica=' + carac.NmCaracteristicaPessoa} />

                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-6">
                                        <div className="card shadow rounded" >
                                            <div className="card-header text-white bg-dark">
                                                <FontAwesomeIcon icon={faTicket} /> Boletos
                                            </div>
                                            <div className="card-body" style={{ height: '200px', overflow: 'auto' }}>
                                                <div className="table-responsive rounded" >
                                                    <table className="table">
                                                        <thead className="table-dark">
                                                            <tr>
                                                                <th scope="col">Produto</th>
                                                                <th scope="col">Nº Titulo</th>
                                                                <th scope="col">Dt. Emissão</th>
                                                                <th scope="col">Dt. Vencimento</th>
                                                                <th scope="col">Valor</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {clienteSelecionado.aReceber.map((rec) => (
                                                                <tr key={rec.IdAReceber} className={`${(new Date(rec.DtVencimento) < new Date()) ? 'table-warning' : ''}`}>
                                                                    <td className="text-truncate ">
                                                                        <div className="align-items-center">
                                                                            {
                                                                                (clienteSelecionado.Caracteristicas.find(c => { return c.idProduto == rec.idProduto })?.NmCaracteristicaPessoa != null)
                                                                                    ? <img width="40px" height="40px" className="img-fluid rounded p-1" src={'http://api.erp.rcasistemas.com.br/api/GetImgCarac?IdCaracteristica=' + clienteSelecionado.Caracteristicas.find(c => { return c.idProduto == rec.idProduto })?.NmCaracteristicaPessoa} />
                                                                                    : <></>
                                                                            }

                                                                        </div>
                                                                    </td>
                                                                    <td className="text-truncate">{rec.NrTitulo}</td>
                                                                    <td className="text-truncate">{format(Date.parse(rec.DtEmissao), 'dd/MM/yyyy')}</td>
                                                                    <td className="text-truncate">{format(Date.parse(rec.DtVencimento), 'dd/MM/yyyy')}</td>
                                                                    <td className={`text-truncate`}>{rec.VlTitulo}</td>
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {exibirVersoes && (
                                        <div className="col-12 col-xl-6">
                                            <div className="card shadow rounded" >
                                                <div className="card-header text-white bg-dark">
                                                    <FontAwesomeIcon icon={faCodeCompare} /> Versões liberadas
                                                </div>

                                                <div className="card-body p-1" style={{ height: '200px', overflow: 'auto' }}>
                                                    <ul className="nav nav-pills my-2" role="tablist">
                                                        <li className="nav-item me-1" role="presentation">
                                                            <button onClick={() => { setConfirmarLiberarVersao(false); setVersaoLiberar(null) }} className="nav-link active" data-bs-toggle="pill" href="#v-pills-farma" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Farma</button>
                                                        </li>
                                                        <li className="nav-item me-1" role="presentation">
                                                            <button onClick={() => { setConfirmarLiberarVersao(false); setVersaoLiberar(null) }} className="nav-link" href="#v-pills-agente" data-bs-toggle="pill" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Agente</button>
                                                        </li>
                                                        <li className="nav-item me-1" role="presentation">
                                                            <button onClick={() => { setConfirmarLiberarVersao(false); setVersaoLiberar(null) }} className="nav-link" href="#v-pills-gpc" data-bs-toggle="pill" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">GPC</button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button onClick={() => { setConfirmarLiberarVersao(false); setVersaoLiberar(null) }} className="nav-link" href="#v-pills-farmaweb" data-bs-toggle="pill" type="button" role="tab" aria-controls="pills-disabled" aria-selected="false" >Farma Web</button>
                                                        </li>
                                                    </ul>

                                                    <div className="tab-content" id="v-pills-tabContent">
                                                        <div className="tab-pane fade show active" id="v-pills-farma" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex="0">
                                                            <div className="table-responsive rounded">
                                                                <table className="table">
                                                                    <thead className="table-dark">
                                                                        <tr>
                                                                            <th scope="col">Versão</th>
                                                                            <th scope="col">Data</th>
                                                                            <th scope="col">Pacote</th>
                                                                            <th scope="col"></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {versoesSistemaFarma.length > 0 ? (
                                                                            versoesSistemaFarma.map((ver, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{ver.versao}</td>
                                                                                    <td>{new Date(ver.dataCriado).toLocaleDateString()}</td>
                                                                                    <td>{ver.pacote}</td>
                                                                                    <td>
                                                                                        <div className='d-flex flex-column justify-content-between'>
                                                                                            {confirmarLiberarVersao && versaoLiberar == ver?.versao && (
                                                                                                <>
                                                                                                    <div className='col-12'>
                                                                                                        <small className='text-danger text-center'><strong>Liberar versão?</strong></small>
                                                                                                    </div>
                                                                                                    <button className='btn me-1 btn-sm btn-outline-success' onClick={() => { liberarVersao(ver) }}>
                                                                                                        <FontAwesomeIcon icon={faCheck} /> Sim
                                                                                                    </button>
                                                                                                    <button className='btn btn-sm btn-outline-danger mt-1' onClick={() => setConfirmarLiberarVersao(false)}>
                                                                                                        <FontAwesomeIcon icon={faCancel} /> Não
                                                                                                    </button>
                                                                                                </>
                                                                                            )}
                                                                                        </div>


                                                                                        {!confirmarLiberarVersao && (
                                                                                            <button className='btn btn-sm btn-outline-primary' onClick={() => { setConfirmarLiberarVersao(true); setVersaoLiberar(ver.versao) }}>
                                                                                                <FontAwesomeIcon icon={faPlus} /> Liberar
                                                                                            </button>
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan="4" className="text-center">Nenhuma versão encontrada</td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="v-pills-agente" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabIndex="0">
                                                            <div className="table-responsive rounded">
                                                                <table className="table">
                                                                    <thead className="table-dark">
                                                                        <tr>
                                                                            <th scope="col">Versão</th>
                                                                            <th scope="col">Data</th>
                                                                            <th scope="col">Pacote</th>
                                                                            <th scope="col"></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {versoesSistemaAgente.length > 0 ? (
                                                                            versoesSistemaAgente.map((ver, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{ver.versao}</td>
                                                                                    <td>{new Date(ver.dataCriado).toLocaleDateString()}</td>
                                                                                    <td>{ver.pacote}</td>
                                                                                    <td>
                                                                                        <div className='d-flex flex-column justify-content-between'>
                                                                                            {confirmarLiberarVersao && versaoLiberar == ver?.versao && (
                                                                                                <>
                                                                                                    <div className='col-12'>
                                                                                                        <small className='text-danger text-center'><strong>Liberar versão?</strong></small>
                                                                                                    </div>
                                                                                                    <button className='btn me-1 btn-sm btn-outline-success' onClick={() => { liberarVersao(ver) }}>
                                                                                                        <FontAwesomeIcon icon={faCheck} /> Sim
                                                                                                    </button>
                                                                                                    <button className='btn btn-sm btn-outline-danger mt-1' onClick={() => setConfirmarLiberarVersao(false)}>
                                                                                                        <FontAwesomeIcon icon={faCancel} /> Não
                                                                                                    </button>
                                                                                                </>
                                                                                            )}
                                                                                        </div>


                                                                                        {!confirmarLiberarVersao && (
                                                                                            <button className='btn btn-sm btn-outline-primary' onClick={() => { setConfirmarLiberarVersao(true); setVersaoLiberar(ver.versao) }}>
                                                                                                <FontAwesomeIcon icon={faPlus} /> Liberar
                                                                                            </button>
                                                                                        )}

                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan="4" className="text-center">Nenhuma versão encontrada</td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="v-pills-gpc" role="tabpanel" aria-labelledby="v-pills-disabled-tab" tabIndex="0">
                                                            <div className="table-responsive rounded">
                                                                <table className="table">
                                                                    <thead className="table-dark">
                                                                        <tr>
                                                                            <th scope="col">Versão</th>
                                                                            <th scope="col">Data</th>
                                                                            <th scope="col">Pacote</th>
                                                                            <th scope="col"></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {versoesSistemaGPC.length > 0 ? (
                                                                            versoesSistemaGPC.map((ver, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{ver.versao}</td>
                                                                                    <td>{new Date(ver.dataCriado).toLocaleDateString()}</td>
                                                                                    <td>{ver.pacote}</td>
                                                                                    <td>
                                                                                        <div className='d-flex flex-column justify-content-between'>
                                                                                            {confirmarLiberarVersao && versaoLiberar == ver?.versao && (
                                                                                                <>
                                                                                                    <div className='col-12'>
                                                                                                        <small className='text-danger text-center'><strong>Liberar versão?</strong></small>
                                                                                                    </div>
                                                                                                    <button className='btn me-1 btn-sm btn-outline-success' onClick={() => { liberarVersao(ver); }}>
                                                                                                        <FontAwesomeIcon icon={faCheck} /> Sim
                                                                                                    </button>
                                                                                                    <button className='btn btn-sm btn-outline-danger mt-1' onClick={() => setConfirmarLiberarVersao(false)}>
                                                                                                        <FontAwesomeIcon icon={faCancel} /> Não
                                                                                                    </button>
                                                                                                </>
                                                                                            )}
                                                                                        </div>


                                                                                        {!confirmarLiberarVersao && (
                                                                                            <button className='btn btn-sm btn-outline-primary' onClick={() => { setConfirmarLiberarVersao(true); setVersaoLiberar(ver.versao) }}>
                                                                                                <FontAwesomeIcon icon={faPlus} /> Liberar
                                                                                            </button>
                                                                                        )}

                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan="4" className="text-center">Nenhuma versão encontrada</td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                        <div className="tab-pane fade" id="v-pills-farmaweb" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabIndex="0">
                                                            <div className="table-responsive rounded">
                                                                <table className="table">
                                                                    <thead className="table-dark">
                                                                        <tr>
                                                                            <th scope="col">Versão</th>
                                                                            <th scope="col">Data</th>
                                                                            <th scope="col">Pacote</th>
                                                                            <th scope="col"></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {versoesSistemaFarmaWeb.length > 0 ? (
                                                                            versoesSistemaFarmaWeb.map((ver, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{ver.versao}</td>
                                                                                    <td>{new Date(ver.dataCriado).toLocaleDateString()}</td>
                                                                                    <td>{ver.pacote}</td>
                                                                                    <td>
                                                                                        <div className='d-flex flex-column justify-content-between'>
                                                                                            {confirmarLiberarVersao && versaoLiberar == ver?.versao && (
                                                                                                <>
                                                                                                    <div className='col-12'>
                                                                                                        <small className='text-danger text-center'><strong>Liberar versão?</strong></small>
                                                                                                    </div>
                                                                                                    <button className='btn me-1 btn-sm btn-outline-success' onClick={() => { liberarVersao(ver) }}>
                                                                                                        <FontAwesomeIcon icon={faCheck} /> Sim
                                                                                                    </button>
                                                                                                    <button className='btn btn-sm btn-outline-danger mt-1' onClick={() => setConfirmarLiberarVersao(false)}>
                                                                                                        <FontAwesomeIcon icon={faCancel} /> Não
                                                                                                    </button>
                                                                                                </>
                                                                                            )}
                                                                                        </div>


                                                                                        {!confirmarLiberarVersao && (
                                                                                            <button className='btn btn-sm btn-outline-primary' onClick={() => { setConfirmarLiberarVersao(true); setVersaoLiberar(ver.versao) }}>
                                                                                                <FontAwesomeIcon icon={faPlus} /> Liberar
                                                                                            </button>
                                                                                        )}

                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan="4" className="text-center">Nenhuma versão encontrada</td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                            <div className="modal-footer">
                                {(clienteSelecionado.UsuarioFranquia != null && ((clienteSelecionado.UsuarioFranquia?.Perfil ?? 0) == 2))
                                    ? (!clienteSelecionado.Bloqueado)
                                        ? <button type="button" className="btn btn-danger" onClick={handleBloquarFranquiado}>
                                            <FontAwesomeIcon icon={faLock} /> Bloquear
                                        </button>
                                        : <button type="button" className="btn btn-success" onClick={handleLiberarFranquiado}>
                                            <FontAwesomeIcon icon={faLockOpen} /> Liberar
                                        </button>
                                    : ''
                                }
                                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                                    <FontAwesomeIcon icon={faDoorClosed} /> Fechar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ModalDetalheCliente;